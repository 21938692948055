.buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
}

.page-wrap {
    width: 90%;
    max-width: 80rem;
    padding: 4rem;
}

@media screen and (min-width: $breakpoint-1 ) {}

@media screen and (min-width: $breakpoint-2 ) {}

@media screen and (min-width: $breakpoint-3 ) {}

@media screen and (min-width: $breakpoint-4 ) {}

@media screen and (min-width: $breakpoint-5 ) {}

@media screen and (min-width: $breakpoint-6 ) {}

@media screen and (min-width: $breakpoint-7 ) {}