.component-wrap.stats {

    .component-inner {}

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.5);

    .nested-wrap {
        flex-grow: 1;
        align-self: stretch;
        display: flex;
    }

}