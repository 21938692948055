.component-wrap.nested {

    .component-inner {}

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: rgba(255, 255, 255, 0.5);
    color: $brand-white;

}