html, body, div, header, footer, main, section, nav, h1, h2, h3, h4, h5, h6, img, svg, p, a, span, ol, ul, li, form, input, label {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: none;
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

input {
  border-radius: 0;
}

::placeholder {
  opacity: 1;
}

a {
  color: inherit;
  -webkit-tap-highlight-color: #0000;
  background-color: #0000;
  outline: none;
  text-decoration: none;
}

html {
  font-size: 10px;
}

body {
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  background-color: #000;
  line-height: 1.5;
  position: relative;
}

.link, .clickable, .dev-wrap .close {
  cursor: pointer;
}

body {
  width: 100%;
  overflow: hidden;
}

.fullscreen, .component-wrap.svg-canvas .canvas, .svg-canvas-wrap, .container {
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.container {
  position: relative;
  overflow-y: scroll;
}

.container-inner {
  min-height: calc(var(--vh, 1vh) * 100);
  opacity: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.container-inner.hidden {
  opacity: 0;
}

.component-wrap {
  flex-grow: 1;
  align-self: stretch;
  display: flex;
}

.component-wrap .component-inner {
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.component-wrap.hidden {
  opacity: 0;
}

.page {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

img, svg, video, iframe {
  width: 100%;
  height: auto;
  display: block;
}

div {
  background-repeat: no-repeat;
  background-size: contain;
}

img:not([src]) {
  visibility: hidden;
}

iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-container {
  position: relative;
}

body {
  position: fixed;
  overflow: hidden;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-black.woff2") format("woff2");
  font-display: swap;
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-heavy.woff2") format("woff2");
  font-display: swap;
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-bold.woff2") format("woff2");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-medium.woff2") format("woff2");
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-regular.woff2") format("woff2");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-book.woff2") format("woff2");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-light.woff2") format("woff2");
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: texta;
  src: url("assets/fonts/texta/texta-narrow-thin.woff2") format("woff2");
  font-display: swap;
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url("assets/fonts/roboto/roboto_mono_bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url("assets/fonts/roboto/roboto_mono_bolditalic.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url("assets/fonts/roboto/roboto_mono_medium.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url("assets/fonts/roboto/roboto_mono_mediumitalic.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url("assets/fonts/roboto/roboto_mono_regular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url("assets/fonts/roboto/roboto_mono_regularitalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url("assets/fonts/roboto/roboto_mono_light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url("assets/fonts/roboto/roboto_mono_lightitalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url("assets/fonts/roboto/roboto_mono_thin.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: roboto;
  src: url("assets/fonts/roboto/roboto_mono_thinitalic.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

.texta, .page.nested-a .body, .page.nested-deep-b .body, .page.nested-a .header, .page.nested-deep-b .header, .page.gallery .body, .page.gallery .header, .page.about .body, .page.about .header, .page.home .body, .page.home .header, body {
  font-family: texta;
  font-weight: 400;
  line-height: 1.35;
}

.texta .headline-text, .page.nested-a .body .headline-text, .page.nested-deep-b .body .headline-text, .texta .page.nested-a .header, .page.nested-a .texta .header, .page.nested-a .body .header, .texta .page.nested-deep-b .header, .page.nested-deep-b .texta .header, .page.nested-deep-b .body .header, .page.nested-a .header .headline-text, .page.nested-a .header .header, .page.nested-deep-b .header .headline-text, .page.nested-deep-b .header .header, .page.gallery .body .headline-text, .texta .page.gallery .header, .page.gallery .texta .header, .page.gallery .body .header, .page.gallery .header .headline-text, .page.gallery .header .header, .page.about .body .headline-text, .texta .page.about .header, .page.about .texta .header, .page.about .body .header, .page.about .header .headline-text, .page.about .header .header, .page.home .body .headline-text, .texta .page.home .header, .page.home .texta .header, .page.home .body .header, .page.home .header .headline-text, .page.home .header .header, body .headline-text, body .page.nested-a .header, .page.nested-a body .header, body .page.nested-deep-b .header, .page.nested-deep-b body .header, body .page.gallery .header, .page.gallery body .header, body .page.about .header, .page.about body .header, body .page.home .header, .page.home body .header {
  font-size: 3em;
}

.texta .body-text, .texta .page.nested-a .body, .page.nested-a .texta .body, .texta .page.nested-deep-b .body, .page.nested-deep-b .texta .body, .page.nested-a .body .body-text, .page.nested-a .body .body, .page.nested-deep-b .body .body-text, .page.nested-deep-b .body .body, .page.nested-a .header .body-text, .page.nested-a .header .body, .page.nested-deep-b .header .body-text, .page.nested-deep-b .header .body, .texta .page.gallery .body, .page.gallery .texta .body, .page.gallery .body .body-text, .page.gallery .body .body, .page.gallery .header .body-text, .page.gallery .header .body, .texta .page.about .body, .page.about .texta .body, .page.about .body .body-text, .page.about .body .body, .page.about .header .body-text, .page.about .header .body, .texta .page.home .body, .page.home .texta .body, .page.home .body .body-text, .page.home .body .body, .page.home .header .body-text, .page.home .header .body, body .body-text, body .page.nested-a .body, .page.nested-a body .body, body .page.nested-deep-b .body, .page.nested-deep-b body .body, body .page.gallery .body, .page.gallery body .body, body .page.about .body, .page.about body .body, body .page.home .body, .page.home body .body {
  margin-bottom: 2rem;
  font-size: 1.2em;
  line-height: 1.8;
}

em {
  font-style: italic;
}

body {
  font-size: 1em;
}

.buttons-row {
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  display: flex;
}

.page-wrap, .page.nested-a .wrap, .page.nested-deep-b .wrap, .page.gallery .wrap, .page.about .wrap, .page.home .wrap {
  width: 90%;
  max-width: 80rem;
  padding: 4rem;
}

.button {
  cursor: pointer;
  color: #000;
  background-color: #fff;
  flex-grow: 1;
  margin-right: 1rem;
  padding: 1rem;
}

.button:last-child {
  margin-right: 0;
}

.button:hover {
  color: #fff;
  background-color: #000;
}

.dev-wrap {
  top: 0;
  right: calc(var(--scrollbar-width, 12px));
  position: fixed;
}

.dev-wrap .component-wrap.dev {
  color: #fff;
  background-color: #fff3;
  width: 28rem;
  height: 32rem;
  padding: 1rem;
  font-family: roboto;
  display: none;
}

.dev-wrap .component-wrap.dev.active {
  display: flex;
}

.dev-wrap .header-row {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
}

.dev-wrap .header {
  font-size: 1.8em;
  font-weight: 600;
}

.dev-wrap .close {
  opacity: .5;
  width: 3rem;
  margin-left: auto;
  padding: .4rem;
}

.dev-wrap .close svg g {
  stroke: #fff;
}

.dev-wrap .close:active, .dev-wrap .close:focus {
  opacity: 1;
}

.dev-wrap .gui-wrap {
  width: 100%;
}

.dev-wrap .dg.main {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  display: flex;
}

.dev-wrap .dg.main .close-button {
  display: none;
}

.dev-wrap .dg.main .slider {
  margin-left: 0;
}

.dev-wrap .dg.main .c input[type="text"] {
  height: 19px;
}

.not-mobile .dev-wrap .close:hover {
  opacity: .85;
}

.component-wrap.stats {
  background-color: #00000080;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.component-wrap.stats .nested-wrap {
  flex-grow: 1;
  align-self: stretch;
  display: flex;
}

.component-wrap.nested {
  color: #fff;
  background-color: #ffffff80;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.svg-canvas-wrap {
  width: calc(100% - var(--scrollbar-width, 0px));
  position: fixed;
}

.page.home .wrap {
  background-color: #e50b66;
}

.page.home .header, .page.home .body {
  margin-bottom: 2rem;
}

.page.home .stats-wrap {
  height: 30rem;
  margin-top: 4rem;
}

.page.about .wrap {
  background-color: #1b835b;
}

.page.about .header, .page.about .body {
  margin-bottom: 2rem;
}

.page.about .stats-wrap {
  height: 10rem;
  margin-top: 4rem;
}

.page.gallery .wrap {
  background-color: #4b19d8;
}

.page.gallery .header, .page.gallery .body {
  margin-bottom: 2rem;
}

.page.gallery .body .media-container {
  background-color: #333;
  margin-bottom: 5px;
}

.page.nested-a .wrap, .page.nested-deep-b .wrap {
  background-color: #1b835b;
}

.page.nested-a .header, .page.nested-deep-b .header, .page.nested-a .body, .page.nested-deep-b .body {
  margin-bottom: 2rem;
}

/*# sourceMappingURL=index.29937007.css.map */
