// - - - Roboto Mono - - - //

/*

BOLD         -   700
MEDIUM       -   600
REGULAR      -   500
LIGHT        -   400
THIN         -   300

*/


@font-face {
    font-display: swap;
    font-family: 'roboto';
    src: url('./assets/fonts/roboto/roboto_mono_bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'roboto';
    src: url('./assets/fonts/roboto/roboto_mono_bolditalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'roboto';
    src: url('./assets/fonts/roboto/roboto_mono_medium.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'roboto';
    src: url('./assets/fonts/roboto/roboto_mono_mediumitalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'roboto';
    src: url('./assets/fonts/roboto/roboto_mono_regular.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'roboto';
    src: url('./assets/fonts/roboto/roboto_mono_regularitalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}


@font-face {
    font-display: swap;
    font-family: 'roboto';
    src: url('./assets/fonts/roboto/roboto_mono_light.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'roboto';
    src: url('./assets/fonts/roboto/roboto_mono_lightitalic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'roboto';
    src: url('./assets/fonts/roboto/roboto_mono_thin.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'roboto';
    src: url('./assets/fonts/roboto/roboto_mono_thinitalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}