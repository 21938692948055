.dev-wrap {

    position: fixed;
    top: 0;
    right: calc(var(--scrollbar-width, 12px));


    .component-wrap.dev {
        display: none;

        &.active {
            display: flex;
        }

        width: 28rem;
        height: 32rem;

        padding: 1rem;

        background-color: rgba(255, 255, 255, 0.2);

        color: $brand-white;
        font-family: 'roboto';

    }

    .header-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1rem;
    }

    .header {
        font-size: 1.8em;
        font-weight: 600;
    }

    .close {
        @extend .clickable;
        width: 3.0rem;
        padding: 0.4rem;
        margin-left: auto;

        opacity: 0.5;

        svg g {
            stroke: $brand-white;
        }

        &:active,
        &:focus {
            opacity: 1;
        }
    }

    .gui-wrap {

        width: 100%;

    }

    .dg.main {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;

        .close-button {
            display: none;
        }

        .slider {
            margin-left: 0;
        }

        .c input[type=text] {
            height: 19px;
            // box-sizing: border-box;
            // // padding: 0;
            // margin-top: 0;
            // margin-bottom: 0;
            // padding-top: 0;
            // padding-bottom: 0;
        }
    }


}


.not-mobile .dev-wrap {

    .close:hover {
        opacity: 0.85;
    }

}