.svg-canvas-wrap {

    position: fixed;

    @extend .fullscreen;

    width: calc(100% - var(--scrollbar-width, 0px));

}

.component-wrap.svg-canvas {

    .canvas {
        @extend .fullscreen;
    }

    .component-inner {}

}