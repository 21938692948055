// FONT

html {
    // 1rem = 10px
    font-size: 10px; // 1rem = 10px
}

body {
    @extend .texta;
    line-height: 1.5;
    background-color: $brand-black;
    color: $brand-white;
    position: relative;

    user-select: none;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
}

.link {
    cursor: pointer;
}

.clickable {
    cursor: pointer;
}

body {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
}

.fullscreen {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    max-height: calc(var(--vh, 1vh) * 100);
}

.container {
    @extend .fullscreen;

    position: relative;

    overflow-y: scroll;
}

.container-inner {

    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    min-height: calc(var(--vh, 1vh) * 100);

    top: 0;
    left: 0;
    opacity: 1;

    &.hidden {
        opacity: 0;
    }

}

.component-wrap {
    flex-grow: 1;
    align-self: stretch;
    display: flex;

    .component-inner {
        flex-grow: 1;
        align-self: stretch;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        display: flex;
    }

    &.hidden {
        opacity: 0;
    }
}

.page {
    width: 100%;

    margin-top: auto;
    margin-bottom: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

img,
svg,
video,
iframe {
    width: 100%;
    height: auto;
    display: block;
}

div {
    background-repeat: no-repeat;
    background-size: contain;
}

img:not([src]) {
    visibility: hidden;
}

iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-container {
    position: relative;
}

body {
    overflow: hidden;
    position: fixed;
}